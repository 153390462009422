import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import Tooltip from '@mui/material/Tooltip'

import AssignInterventionsContent from './AssignInterventionsContent'

const PREFIX = 'AssignInterventions'

const classes = {
	root: `${PREFIX}-root`,
	roleContainer: `${PREFIX}-roleContainer`,
	fab: `${PREFIX}-fab`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: theme.spacing(4),
	},

	[`& .${classes.roleContainer}`]: {
		paddingTop: theme.spacing(4),
	},

	[`& .${classes.fab}`]: {
		position: 'fixed',
		bottom: theme.spacing(4),
		right: theme.spacing(2.9),
	},
}))

const AssignInterventions = ({ intervention, handleClickOpenInterventionCreate }) => {
	return (
		<Root className={classes.root}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<AssignInterventionsContent handleClickOpenInterventionCreate={handleClickOpenInterventionCreate} />
				</Grid>
			</Grid>
			<Tooltip
				placement="left"
				arrow
				title="Create a new intervention from scratch"
				aria-label="create from scratch"
			>
				<Fab
					variant="circular"
					aria-label={'Create New'}
					className={classes.fab}
					color={'primary'}
					onClick={handleClickOpenInterventionCreate}
				>
					<AddIcon />
				</Fab>
			</Tooltip>
		</Root>
	)
}

AssignInterventions.propTypes = {}

const mapStateToProps = ({ intervention }) => {
	return {
		intervention,
	}
}

export default connect(mapStateToProps, actions)(AssignInterventions)
