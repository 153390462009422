import React, { Component } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { InterventionsOptionsTable } from '@components'
import { cacheDuration } from '../../../../environment'

const PREFIX = 'AssignInterventionsContent'

const classes = {
	root: `${PREFIX}-root`,
	paper: `${PREFIX}-paper`,
	tabs: `${PREFIX}-tabs`,
	createYourOwnContainer: `${PREFIX}-createYourOwnContainer`,
	createButton: `${PREFIX}-createButton`,
}

const Root = styled('div')(({ theme }) => {
	return {
		[`&.${classes.root}`]: {
			padding: theme.spacing(5),
			paddingTop: theme.spacing(1),
		},
		[`& .${classes.paper}`]: {},
		[`& .${classes.tabs}`]: {
			marginBottom: theme.spacing(4),
		},
		[`& .${classes.createYourOwnContainer}`]: {
			borderRadius: 5,
			borderWidth: 1,
			borderColor: theme.palette.primary[500],
			borderStyle: 'solid',
			padding: '16px 26px',
			backgroundColor: theme.palette.background.default,
		},
		[`& .${classes.createButton}`]: {
			width: '100%',
		},
	}
})

class AssignInterventionsContent extends Component {
	constructor(props) {
		super(props)
	}

	state = {}

	shouldComponentUpdate = (nextProps, nextState) => {
		return true
	}

	componentDidMount = () => {
		const lastFetched = this.props.analytics.interventionRecs.lastFetched
		if (!lastFetched) {
			this.hydrateData()
		} else if (lastFetched + cacheDuration <= new Date().getTime()) {
			this.hydrateData()
		}
	}

	hydrateData = () => {
		if (!this.props.analytics.interventionRecs.loading.read) {
			this.props.fetchInterventionRecs()
			this.props.fetchInterventionsSuperset()
		}
	}

	onRefresh = () => {
		this.props.fetchInterventionRecs()
		this.props.fetchInterventionsSuperset()
	}

	render() {
		const {
			intervention,
			survey,
			question,
			assembleSurvey,
			title,
			defaultOrder,
			clearCurrentIntervention,
			handleClickOpenInterventionCreate,
		} = this.props
		const now = new Date().getTime()
		const interventionStates = intervention?.data.reduce(
			(acc, item) => {
				if (!item.occurred_at) {
					acc.queued.push(item)
				} else {
					const occurred_at = new Date(item.occurred_at)
					if (Number.isInteger(item.effect)) {
						acc.historical.push(item)
					} else if (now <= occurred_at.getTime() + item.duration * 1000) {
						acc.active.push(item)
					} else {
						acc.concluding.push(item)
					}
				}
				return acc
			},
			{
				queued: [],
				active: [],
				concluding: [],
				historical: [],
			}
		)

		const activeInterventions = [
			...interventionStates.concluding,
			...interventionStates.queued,
			...interventionStates.active,
		]
		const showToast = activeInterventions.length
		const subject = this.props.patient.current ? 'Patient is' : "You're"
		return (
			<Root className={classes.root}>
				<Grid container justifyContent="center" alignItems="center">
					<Grid item>
						<Grid container spacing={4}>
							{showToast ? (
								<Grid item xs={12}>
									<Alert variant="outlined" severity="warning">
										<AlertTitle>{subject} currently on an intervention</AlertTitle>
										We recommend keeping the active interventions to a minimum to more clearly
										isolate trigger / symptom relationships.
									</Alert>
								</Grid>
							) : (
								<></>
							)}
							<Grid item xs={12}>
								<Paper className={classes.paper}>
									<InterventionsOptionsTable
										title="Dieta Recommended Interventions"
										interventions={this.props.analytics.interventionRecs.top}
										handleClickOpenInterventionCreate={this.props.handleClickOpenInterventionCreate}
										setCurrentIntervention={this.props.setCurrentIntervention}
									/>
								</Paper>
							</Grid>
							<Grid item xs={12}>
								<Paper className={classes.paper}>
									<InterventionsOptionsTable
										title="All Interventions"
										interventions={this.props.intervention.collection}
										handleClickOpenInterventionCreate={this.props.handleClickOpenInterventionCreate}
										setCurrentIntervention={this.props.setCurrentIntervention}
									/>
								</Paper>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Root>
		)
	}
}

AssignInterventionsContent.propTypes = {
	patient: PropTypes.shape({
		current: PropTypes.shape({
			id: PropTypes.number,
		}),
	}),
	intervention: PropTypes.shape({
		data: PropTypes.array,
		collection: PropTypes.array,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	analytics: PropTypes.shape({
		interventionRecs: PropTypes.shape({
			lastFetched: PropTypes.number,
			top: PropTypes.array,
			loading: PropTypes.shape({
				read: PropTypes.bool.isRequired,
			}),
		}),
	}),
	fetchInterventionRecs: PropTypes.func.isRequired,
	setCurrentIntervention: PropTypes.func.isRequired,
	clearCurrentIntervention: PropTypes.func.isRequired,
}

const mapStateToProps = ({ patient, intervention, analytics }) => {
	return {
		patient,
		intervention,
		analytics,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(AssignInterventionsContent)
