import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid'
import ViewInterventionsContent from './ViewInterventionsContent'

const PREFIX = 'ViewInterventions'

const classes = {
	root: `${PREFIX}-root`,
	roleContainer: `${PREFIX}-roleContainer`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		paddingTop: theme.spacing(3),
		padding: theme.spacing(1),
	},

	[`& .${classes.roleContainer}`]: {
		paddingTop: theme.spacing(4),
	},
}))

const ViewInterventions = ({ intervention }) => {
	return (
		<Root className={classes.root}>
			<Grid container>
				<Grid item xs={12}>
					<ViewInterventionsContent />
				</Grid>
			</Grid>
		</Root>
	)
}

ViewInterventions.propTypes = {}

const mapStateToProps = ({ intervention }) => {
	return {
		intervention,
	}
}

export default connect(mapStateToProps, actions)(ViewInterventions)
