import React, { Component } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'

import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { InterventionsTable, ActiveInterventionsTable } from '@components'
import SurveyQuestionsBrowser from './SurveyQuestionsBrowser'
import { cacheDuration } from '../../../../environment'

const PREFIX = 'ViewInterventionsContent'

const classes = {
	root: `${PREFIX}-root`,
	paper: `${PREFIX}-paper`,
	tabs: `${PREFIX}-tabs`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: theme.spacing(4),
	},
	[`& .${classes.paper}`]: {
		width: '100%',
	},

	[`& .${classes.tabs}`]: {
		marginBottom: theme.spacing(4),
	},
}))

class ViewInterventionsContent extends Component {
	constructor(props) {
		super(props)
	}

	state = {}

	static getDerivedStateFromProps(props, state) {
		const now = new Date().getTime()
		const interventionStates = props.intervention?.data.reduce(
			(acc, item) => {
				if (!item.occurred_at) {
					acc.queued.push(item)
				} else {
					if (item.completed_at) {
						acc.historical.push(item)
					} else if (item.completed_at && !Number.isInteger(item.effect)) {
						acc.concluding.push(item)
					} else {
						acc.active.push(item)
					}
				}
				return acc
			},
			{
				queued: [],
				active: [],
				concluding: [],
				historical: [],
			}
		)

		const activeInterventions = [...interventionStates.queued, ...interventionStates.active].filter((a) => {
			if (a.occurred_at && a.completed_at) {
				return moment(a.occurred_at).add(1, 'day').isBefore(a.completed_at)
			}
			return true
		})
		const historicalInterventions = (interventionStates.historical || []).filter((a) => {
			if (a.occurred_at && a.completed_at) {
				return moment(a.occurred_at).add(1, 'day').isBefore(a.completed_at)
			}
			return true
		})

		return {
			...state,
			activeInterventions,
			historicalInterventions,
		}
	}

	shouldComponentUpdate = (nextProps, nextState) => {
		return true
	}

	componentDidMount = () => {
		const lastFetched = this.props.intervention.lastFetched
		if (!lastFetched) {
			this.hydrateData()
		} else if (lastFetched + cacheDuration <= new Date().getTime()) {
			this.hydrateData()
		}
	}

	hydrateData = async () => {
		if (!this.props.intervention.loading.read) {
			await this.props.fetchInterventions()
		}
		// this.props.getBowels({
		// 	from: new Date('2017-01-01T00:00:00.000Z').toISOString(),
		// })
		// if (!this.props.intervention.interventionCompliance.loading.read) {
		// 	;[...this.state.activeInterventions, ...this.state.historicalInterventions].map((interv) => {
		// 		if (!this.props.intervention.interventionCompliance.interventionMap[interv.id]) {
		// 			this.props.fetchInterventionCompliance({
		// 				user_intervention_id: interv.id,
		// 			})
		// 		}
		// 	})
		// }
	}

	onRefresh = () => {
		this.hydrateData()
	}

	hydrateInterventionChildDetails = (intervention, dates) => {
		if (dates) {
			// Only needing symptom score in page view
			// this.props.getBowels({
			// 	from: dates.from,
			// 	to: dates.to,
			// })
			this.props.fetchSymptomScores({
				from: dates.from,
				to: dates.to,
			})
		} else {
			const from = moment(intervention.occurred_at).toISOString()
			const to = intervention.completed_at
				? moment(intervention.completed_at).toISOString()
				: moment().toISOString()
			this.props.fetchInterventionCompliance({
				user_intervention_id: intervention.id,
			})
			this.props.getBowels({
				from,
				to,
			})
			// this.props.fetchSymptomScores({
			// 	from,
			// 	to,
			// })
		}
	}

	render() {
		const { intervention, symptomScore, survey, question, assembleSurvey, title, defaultOrder } = this.props

		return (
			<Root className={classes.root}>
				<Stack spacing={4}>
					{/* <Container> */}
					<Paper className={classes.paper}>
						<ActiveInterventionsTable
							loading={intervention.loading.read}
							clinicians={this.props.clinician?.data || []}
							symptomScore={symptomScore}
							interventions={this.state.activeInterventions}
							interventionCompliance={this.props.intervention.interventionCompliance}
							onRefresh={this.onRefresh}
							hydrateChildren={this.hydrateInterventionChildDetails}
						/>
					</Paper>
					{/* </Container> */}
					{!!this.state.historicalInterventions.length && (
						// <Container>
						<Paper className={classes.paper}>
							<InterventionsTable
								loading={intervention.loading.read}
								symptomScore={symptomScore}
								interventions={this.state.historicalInterventions}
								onRefresh={this.onRefresh}
								interventionCompliance={this.props.intervention.interventionCompliance}
								hydrateChildren={this.hydrateInterventionChildDetails}
							/>
						</Paper>
						// </Container>
					)}
					{/* <Container> */}
					<Paper
						className={classes.paper}
						style={{
							maxHeight: 600,
						}}
					>
						<SurveyQuestionsBrowser className={classes.tabs} />
					</Paper>
					{/* </Container> */}
				</Stack>
				{/* <Grid container justifyContent="center" alignItems="center">
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								
							</Grid>
							<Grid item xs={12}>
								
							</Grid>
							{historicalInterventions.length ? (
								<Grid item xs={12}>
									<Paper className={classes.paper} style={{}}>
										<InterventionsTable
											loading={intervention.loading.read}
											symptomScore={symptomScore}
											interventions={historicalInterventions}
											onRefresh={this.onRefresh}
											interventionCompliance={this.props.intervention.interventionCompliance}
											hydrateInterventionCompliance={this.hydrateInterventionCompliance}
										/>
									</Paper>
								</Grid>
							) : (
								<></>
							)}
						</Grid>
					</Grid>
				</Grid> */}
			</Root>
		)
	}
}

ViewInterventionsContent.propTypes = {
	intervention: PropTypes.shape({
		data: PropTypes.array,
		interventionCompliance: PropTypes.shape({
			interventionMap: PropTypes.object.isRequired,
			daysCompliance: PropTypes.object.isRequired,
			loading: PropTypes.shape({
				create: PropTypes.bool.isRequired,
				read: PropTypes.bool.isRequired,
				update: PropTypes.bool.isRequired,
				delete: PropTypes.bool.isRequired,
			}),
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	survey: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	question: PropTypes.shape({
		data: PropTypes.array.isRequired,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	getBowels: PropTypes.func.isRequired,
	fetchSymptomScores: PropTypes.func.isRequired,
	fetchInterventions: PropTypes.func.isRequired,
	fetchInterventionCompliance: PropTypes.func.isRequired,
	assembleSurvey: PropTypes.func.isRequired,
}

const mapStateToProps = ({ intervention, symptomScore, survey, question, clinician, getClinicians }) => {
	return {
		intervention,
		symptomScore,
		survey,
		question,
		clinician,
		getClinicians,
	}
}

export default compose(
	connect(mapStateToProps, actions),

	withTranslation()
)(ViewInterventionsContent)
