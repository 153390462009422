import React, { Component } from 'react'
import { styled } from '@mui/material/styles'
import { lighten, darken } from '@mui/system'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Grid from '@mui/material/Grid'
import PhoneIcon from '@mui/icons-material/Phone'
import FavoriteIcon from '@mui/icons-material/Favorite'
import PersonPinIcon from '@mui/icons-material/PersonPin'
import HelpIcon from '@mui/icons-material/Help'
import ShoppingBasket from '@mui/icons-material/ShoppingBasket'
import ThumbDown from '@mui/icons-material/ThumbDown'
import ThumbUp from '@mui/icons-material/ThumbUp'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { StaticSurveyQuestionView, InterventionsTriedTable } from '@components'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDumbbell, faPills, faUtensils, faPrescriptionBottleAlt } from '@fortawesome/free-solid-svg-icons'

const PREFIX = 'SurveyQuestionsBrowser'

const classes = {
	tabs: `${PREFIX}-tabs`,
	expand: `${PREFIX}-expand`,
	inlineTab: `${PREFIX}-inlineTab`,
	distinguishedTab: `${PREFIX}-distinguishedTab`,
	tabsBackground: `${PREFIX}-tabsBackground`,
	tabPanel: `${PREFIX}-tabPanel`,
	titleBar: `${PREFIX}-titleBar`,
	interventionGroupHeader: `${PREFIX}-interventionGroupHeader`,
}

const Root = styled('div')(({ theme }) => ({
	[`& .${classes.expand}`]: {
		flexGrow: 1,
		flex: 1,
	},

	[`& .${classes.distinguishedTab}`]: {
		margin: 15,
		borderColor: 'white',
		borderWidth: 1,
		borderStyle: 'solid',
	},

	[`& .${classes.tabsBackground}`]: {
		minWidth: 190,
	},

	[`&.${classes.tabPanel}`]: {
		maxHeight: 460,
		overflow: 'auto',
		flex: 1,
	},

	[`& .${classes.titleBar}`]: {
		padding: theme.spacing(1),
	},

	[`& .${classes.interventionGroupHeader}`]: {
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(3),
		background: `linear-gradient(90deg, 
      ${
			theme.palette.mode === 'dark'
				? lighten(theme.palette.background.paper, 0.7)
				: darken(theme.palette.background.paper, 0.2)
		} 3%,
      ${theme.palette.background.paper} 90%)`,
	},
}))

const QUESTIONS = {
	DIET_CHANGES: 'Have these dietary changes helped any of your symptoms?',
	DIET: 'Have these diets helped any of your symptoms?',
	MEDS: 'Have these medication changes helped any of your symptoms?',
	LIFESTYLE: 'Have these lifestyle changes helped any of your symptoms?',
	ANTIBIOTICS: 'Have these antibiotics helped any of your symptoms?',
}

function TabContent(props) {
	const answer = props.question.data.find((q) => q.question === props.q)?.answer
	return (
		<>
			<StaticSurveyQuestionView
				showNav={props.showNav}
				requestData={props.requestData}
				survey={props.survey}
				question={props.q}
				answer={answer}
			/>
		</>
	)
}

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-force-tabpanel-${index}`}
			aria-labelledby={`scrollable-force-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

function a11yProps(index) {
	return {
		id: `scrollable-force-tab-${index}`,
		'aria-controls': `scrollable-force-tabpanel-${index}`,
	}
}

const StyledTabs = styled((props) => (
	<Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
	// '& .MuiTabs-indicator': {
	// 	display: 'flex',
	// 	justifyContent: 'center',
	// 	backgroundColor: 'transparent',
	// },
	// '& .MuiTabs-indicatorSpan': {
	// 	width: '100%',
	// 	backgroundColor: 'white',
	// },
})

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
	textTransform: 'none',
	fontWeight: theme.typography.fontWeightRegular,
	fontSize: theme.typography.pxToRem(15),
	marginRight: theme.spacing(1),
	color: 'rgba(255, 255, 255, 0.7)',
	'&.Mui-selected': {
		color: '#fff',
	},
	'&.Mui-focusVisible': {
		backgroundColor: 'rgba(100, 95, 228, 0.32)',
	},
}))

class SurveyQuestionsBrowser extends Component {
	state = {
		value: 0,
	}

	handleChange = (event, newValue) => {
		this.setState({ value: newValue })
	}

	render() {
		const generalGI = this.props.survey.data.find((survey) => {
			return survey.path === 'shared/general_gi.json'
		})

		return generalGI ? (
			<Root>
				<AppBar className={classes.titleBar} position="static" color="default" elevation={0}>
					<Toolbar>
						<Typography variant="h5" color="textSecondary" component="h5">
							Reported Experiment History
						</Typography>
					</Toolbar>
				</AppBar>

				<AppBar position="static" color="primary" elevation={2}>
					<StyledTabs
						value={this.state.value}
						onChange={this.handleChange}
						scrollButtons="auto"
						variant="scrollable"
					>
						<StyledTab
							className={classNames(classes.distinguishedTab)}
							label="Summary"
							// icon={<FontAwesomeIcon icon={faUtensils} />}
							{...a11yProps(0)}
						/>
						<StyledTab
							label="Lifestyle Changes"
							// icon={<FontAwesomeIcon icon={faDumbbell} />}
							{...a11yProps(1)}
						/>
						<StyledTab
							label="Diets"
							// icon={<FontAwesomeIcon icon={faUtensils} />}
							{...a11yProps(2)}
						/>
						<StyledTab
							label="Dietary Changes"
							// icon={<FontAwesomeIcon icon={faUtensils} />}
							{...a11yProps(3)}
						/>
						<StyledTab
							label="Antibiotics"
							// icon={<FontAwesomeIcon icon={faPrescriptionBottleAlt} />}
							{...a11yProps(4)}
						/>
						<StyledTab
							label="Medications"
							// icon={<FontAwesomeIcon icon={faPills} />}
							{...a11yProps(5)}
						/>
					</StyledTabs>
				</AppBar>
				<Grid container className={classes.tabPanel}>
					<TabPanel value={this.state.value} index={0} className={classes.expand}>
						<InterventionsTriedTable question={this.props.question} />
					</TabPanel>
					<TabPanel value={this.state.value} index={1} className={classes.expand}>
						<TabContent
							requestData={this.props.assembleSurvey}
							survey={generalGI}
							question={this.props.question}
							q={QUESTIONS.LIFESTYLE}
						/>
					</TabPanel>
					<TabPanel value={this.state.value} index={2} className={classes.expand}>
						<TabContent
							requestData={this.props.assembleSurvey}
							survey={generalGI}
							question={this.props.question}
							q={QUESTIONS.DIET}
						/>
					</TabPanel>
					<TabPanel value={this.state.value} index={3} className={classes.expand}>
						<TabContent
							requestData={this.props.assembleSurvey}
							survey={generalGI}
							question={this.props.question}
							q={QUESTIONS.DIET_CHANGES}
						/>
					</TabPanel>

					<TabPanel value={this.state.value} index={4} className={classes.expand}>
						<TabContent
							requestData={this.props.assembleSurvey}
							survey={generalGI}
							question={this.props.question}
							q={QUESTIONS.ANTIBIOTICS}
						/>
					</TabPanel>
					<TabPanel value={this.state.value} index={5} className={classes.expand}>
						<TabContent
							requestData={this.props.assembleSurvey}
							survey={generalGI}
							question={this.props.question}
							q={QUESTIONS.MEDS}
							showNav
						/>
					</TabPanel>
				</Grid>
			</Root>
		) : (
			<Grid item xs={12}>
				<Alert variant="outlined" severity="error">
					<AlertTitle>Not yet completed General GI Survey</AlertTitle>
					Once this survey has been completed in the Dieta Mobile app, your information will populate this
					section here
				</Alert>
			</Grid>
		)
	}
}

const mapStateToProps = ({ survey, question }) => {
	return {
		survey,
		question,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(SurveyQuestionsBrowser)
